$primary: #FAED33;
$secondary: rgb(31, 35, 38);
$border-color: #E3EBF6;

$active-color: $primary;

$nav-row-item-border-color-active: $primary;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~@bootstrap-extended-style/bootstrap-extended-style/index";

@import "~@fortawesome/fontawesome-pro/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro/scss/brands";
@import "~@fortawesome/fontawesome-pro/scss/light";
@import "~@fortawesome/fontawesome-pro/scss/regular";
@import "~@fortawesome/fontawesome-pro/scss/solid";
@import "~@fortawesome/fontawesome-pro/scss/duotone";

@import "~react-loading-skeleton/dist/skeleton.css";

@import "background";
@import "discover";
@import "event-detail";
@import "modal";
@import "tabbar";
@import "transition";

.text-decoration-underline {
  text-decoration: underline;
}
