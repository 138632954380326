.modal-dark {
  .modal-content {
    background-color: #212529;
    color: white;

    .modal-header {
      border-color: #495057;
      .modal-title {
      }
      button.close {
        color: white !important;
        opacity: 1;
      }
    }
    .modal-body {
      border-color: #495057;
    }
    .modal-footer {
      border-color: #495057;
    }
  }
  .btn-secondary {
    background-color: #495057;
    border-color: #495057;
    color: white;
    &:hover {
      background-color: #343a40;
      border-color: #343a40;
    }
  }
}

.ticket-scanned {
  color: #44ff2f;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 0;
}

.modal-dark {
  .info-detail-section {
    background-color: #343a40;
    border-radius: 15px;
    margin-bottom: 20px;

    .info-detail-row {
      padding: 8px 16px;
      border-bottom: 1px solid #495057;
      &:last-child {
        border-bottom: none;
      }

      .info-detail-row-title {
        font-size: 0.9rem;
        color: #a2b1bf;
      }
    }
  }
}
