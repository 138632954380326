.card-event-link {
  text-decoration: none !important;

  @include media-breakpoint-up(sm) {
    &:hover {
      .card-event {
        -webkit-filter: brightness(70%);
      }
    }
  }
}

.card-event {
  -webkit-filter: brightness(100%);

  background-color: $secondary;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 10px;

  aspect-ratio: 5/3;

  display: flex;
  flex-direction: column;

  overflow: clip;

  margin-bottom: 15px;

  transition: all 0.3s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    z-index: -1;
  }

  &.live {
    border: 3px solid orangered;
  }

  .live-badge {
    position: absolute;
    top: 10px;
    right: 10px;

    background-color: orangered;
    color: white;

    line-height: 1rem;
    padding: 3px 8px;
    border-radius: 5px;
    font-weight: 500;
  }

  .event-logo {
    flex-grow: 1;

    margin: 10%;
    @include media-breakpoint-down(sm) {
      margin: 15px;
    }

    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .event-title-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 12px;
    padding-top: 12px;

    background-color: rgba($secondary, .5);
    color: white;
    backdrop-filter: blur(20px);

    .event-title {
      margin: 0;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.35rem;
    }
  }
}
