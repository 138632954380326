$border-color: #E3EBF6;

.tabbar {
  border-top: darken($primary, 0.5) 1px solid;
  box-shadow: none;
  background-color: $primary !important;
}

.tabbar-link {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;

  display: flex;
  flex-direction: row;
  align-items: center;
  .tabbar-icon {
    font-size: 1.3rem;
    margin-right: 8px;
  }

  @include media-breakpoint-down(xs) {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 0.8rem;
  }
  .tabbar-icon {
    @include media-breakpoint-down(xs) {
      font-size: 1.5rem;
      margin-right: 0;
    }
  }
}

.navbar-nav {
  .active {
    .nav-link {
      color: $secondary !important;
    }
  }
}
