.drvr-background {
  display: flex;
  flex-direction: column;

  position: relative;
  min-height: 100vh;
  padding-bottom: 80px !important;
}

@supports (-webkit-touch-callout: none) {
  .drvr-background {
    min-height: -webkit-fill-available;
  }
}
@supports (min-height: 100svh) {
  .drvr-background {
    min-height: 100svh;
  }
}

.background-login {
  @extend .drvr-background;

  background: linear-gradient(to bottom right, rgba(70, 70, 70, 0.8), rgba(0, 0, 0, 0.8)), url("../img/background-login.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;

  padding: 30px;
  @include media-breakpoint-down(sm) {
    padding: 10px;
  }
}

.background-black {
  @extend .drvr-background;

  background-color: #000;
}

.session-load-overlay {
  @extend .background-black;
  z-index: 2000;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;

  padding: 30px;
}
