.event-banner {
  background-color: #1f2326;

  position: relative;
  width: 100%;
  min-height: 65svh;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  .event-banner-image {
    position: absolute;
    width: 100%;
    height: 100%;

    background-size: cover;
    background-position: center;
  }

  .back-button-container {
    z-index: 5;
    width: 100%;
    flex-grow: 1;
    padding: 15px;

    .back-button {
      color: white;
      font-size: 1.5rem;
      font-weight: 500;
      text-decoration: none;
    }
  }

  .event-banner-overlay {
    z-index: 1;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 21%, rgba(0,0,0,0.4) 100%);

    padding: 30px 15px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .event-logo-container {
      max-width: 600px;
      height: 250px;
      .event-logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center bottom;
      }
      margin-bottom: 20px;
    }
    .event-description {
      position: relative; // For the ellipsis
      text-align: center;

      width: 100%;
      max-width: 600px;
      color: white;
      font-size: 1.2rem;
      font-weight: 500;

      line-height: 1.5rem;
      max-height: 4.5rem;
      overflow: hidden;

      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .react-loading-skeleton {
    --base-color: #1f2326;
    --highlight-color: #2c3033;
  }
}

.event-detail-button {
  background-color: $secondary;
  border-radius: 10px;

  padding-top: 16px;
  padding-left: 24px;
  padding-bottom: 40px;
  padding-right: 30px;
  min-width: 150px;

  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;

  .event-detail-button-icon {
    color: $primary;
    font-size: 1.5rem;
  }
  .event-detail-button-text {
    color: white;
    font-size: 1.5rem;
  }
}
